import AWS from 'aws-sdk';

// Configure AWS SDK
AWS.config.update({
  region: 'us-east-1',  // Adjust the region as necessary
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();

export const getChatbotIntent = async (userQuestion) => {
  const params = {
    TableName: 'ChatbotIntents', // Your DynamoDB table name
    IndexName: 'question-index',  // Use the correct index name for `question`
    KeyConditionExpression: '#question = :question', // Use the alias for `question`
    ExpressionAttributeNames: { '#question': 'question' },  // Define the alias for `question`
    ExpressionAttributeValues: { ':question': userQuestion } // Set the value for userQuestion
  };

  try {
    const data = await dynamoDB.query(params).promise(); // Perform query on the index
    return data.Items && data.Items.length > 0 
      ? data.Items[0].answer 
      : 'Hold tight! 🚧 Kunal’s still fine-tuning me.🔧';
  } catch (error) {
    console.error('Error fetching data from DynamoDB:', error);
    return 'Error connecting to the chatbot.';
  }
};
