import React from 'react';
import './ChatStyles.css';
const ChatButton = ({ onClick,hidden }) => {
    if (hidden) return null;
    return (
        <button className="chat-button" onClick={onClick}>
            Wana chat with me!
        </button>
    );
};

export default ChatButton;
