import React, { useState, useEffect } from 'react';
import './ChatStyles.css';
import { getChatbotIntent } from '../../services/dynamoService';

const ChatBox = ({ isVisible, onSubmit, initialMessage }) => {
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);

    // Display the initial message when the chat box opens
    useEffect(() => {
        if (initialMessage) {
            setMessages([{ text: initialMessage, isUser: false }]);
            console.log("Initial message set:", [{ text: initialMessage, isUser: false }]);
        }
    }, [initialMessage]);

    if (!isVisible) return null; // Return null if chat box is not visible

    const handleSend = async () => {
        if (input.trim()) {
            const userMessage = input.trim();
            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages, { text: userMessage, isUser: true }];
                console.log("User message added:", updatedMessages);
                return updatedMessages;
            });

            try {
                // Fetch response from DynamoDB
                const botResponse = await getChatbotIntent(userMessage);
                console.log("Bot response:", botResponse);
    
                // Add bot response to messages or fallback message if no response
                setMessages((prevMessages) => {
                    const updatedMessages = [
                        ...prevMessages,
                        { text: botResponse || "Sorry, I didn’t get that.", isUser: false }
                    ];
                    console.log("Updated messages with bot response:", updatedMessages);
                    return updatedMessages;
                });
            } catch (error) {
                console.error("Error fetching response:", error);
                setMessages((prevMessages) => {
                    const updatedMessages = [...prevMessages, { text: "Error connecting to the chatbot.", isUser: false }];
                    console.log("Updated messages with error:", updatedMessages);
                    return updatedMessages;
                });
            }

            onSubmit(userMessage); // Trigger onSubmit callback if necessary
            setInput(''); // Clear input after sending
        }
    };

    return (
        <div className="chat-box transparent">
            {/* Display messages */}
            <div className="chat-messages">
                {messages.map((msg, index) => (
                    <div key={index} className={`chat-message ${msg.isUser ? 'user-message' : 'bot-message'}`}>
                        {msg.text}
                    </div>
                ))}
            </div>
            {/* Input for user messages */}
            <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Ask me anything..."
                className="chat-input"
            />
            {/* Send button */}
            <button className="send-button" onClick={handleSend}>Send</button>
        </div>
    );
};


export default ChatBox;
